<template>
  <div class="email">
    <Header></Header>
    <div class="email-banner">
      <div class="banner-img-font">
        <div class="email-banner-img-font-border">
          <strong>电子邮箱验证</strong>
          <ul>
            <li>验证电子邮箱以后，方便您更全面的接收平台的消息通知，</li>
            <li>或者您的任务进度需求。</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="email-border">
      <div class="email-border-center">

        <el-form :model="emailForm" :rules="rules" ref="emailForm" label-width="70px" class="demo-ruleForm" @submit.native.prevent>
          <el-form-item label="邮箱" prop="email">
            <el-input type="text" :disabled="isBind" placeholder="请输入您的常用邮箱" v-model="emailForm.email"></el-input>
          </el-form-item>

          <el-button size="small" v-if="!isBind" type="primary" @click="submitForm()">下一步</el-button>
        </el-form>
        <el-button size="small" v-if="isBind" type="primary" disabled >您已有认证邮箱</el-button>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import {requestSendEmailCode} from "@/api/index";
import { getUserInfo } from "@/api/user"
export default {

  data() {
    var validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入邮箱'));
      } else {
        var pattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        if (!pattern.test(value)) {
          callback(new Error('您输入邮箱格式错误'));
        }
        callback();
      }
    };
    return {
      emailForm: {
        email: ''
      },
      rules: {
        email: [
          {required: true, message: '请输入邮箱', trigger: 'blur'},
          {validator: validateEmail, trigger: 'blur'}
        ]
      },
      isBind: false
    };
  },
  created() {
    this.getCurrentUser()
  },
  methods: {
    submitForm() {
      var that = this
      this.$refs.emailForm.validate(function (valid) {
        if (valid) {
          that.toSendCode({email: that.emailForm.email, code_type: 1})
        } else {
          that.$notify({
            type: "error",
            title: '提示',
            message: '邮箱填写错误'
          })
        }
      })
    },
    async getCurrentUser(){
      const res = await getUserInfo()
      if( res.statusCode == 200 ) {
        if( res.data.email != '' ){
          this.isBind = true
          this.emailForm.email = res.data.email
        }
      }
    },
    async toSendCode(data) {
      var toUrl = '/email/step2'
      if( this.$route.query.redirect_url != '' && typeof( this.$route.query.redirect_url ) != 'undefined' ){
         toUrl = '/email/step2?redirect_url=' + this.$route.query.redirect_url
      }
      const res = await requestSendEmailCode(data)
      if (res.statusCode == 200) {
        this.$notify({
          message: res.data,
          type: 'success'
        });
        localStorage.setItem( 'current_email', this.emailForm.email )
        this.$router.push( { path: toUrl } )
      } else {
        this.$notify({
          message: res.message,
          type: 'warn'
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.email {
  background-color: #f8f8f8;
}

.banner-img-font {
  background-image: url(~@/assets/banner/底图.jpg);
  width: 100%;
  height: 400px;
  background-size: cover;

  div {
    position: relative;
    width: 800px;
    margin: 0 auto;
    top: 30px;
    color: #fff;

    strong {
      font-size: 18px;
      display: block;
      margin-bottom: 23px;
    }

    li {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}

.email-border {
  width: 800px;
  height: 434px;
  background-color: #fff;
  margin: 0 auto;
  margin-top: -250px;
}

.email-border-center {
  width: 500px;
  margin: 0 auto;
  position: relative;
  top: 100px;
  height: 155px;
  text-align: center;

  input {
    margin-left: 10px;
    width: 300px;
    height: 36px;
    border-color: #b5b5b5;
    margin-bottom: 10px;
  }

  p {
    margin-left: 30px;
  }

  button {
    width: 180px;
    font-size: 14px;
    margin-top: 40px;
  }
}
</style>
